import React from 'react';
import {Navigate} from 'react-router-dom';
import { useCustomHook } from './context';

const ProtectedRoute = ({children})=>{
    const {user} = useCustomHook();

    if(!user){
        return <Navigate to="/"/>
    };
    return children;
};

export default ProtectedRoute;